.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  text-align: center;
}

.wrapper {
  margin-bottom: 260px;
}

.message {
  border: 1px solid rgb(226, 0, 116);
  border-radius: 10px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 5rem;
  color: rgb(226, 0, 116);
  margin: 0;
}

.subtitle {
  font-size: 1.5rem;
  color: rgb(202, 0, 104);
  margin: 0;
}

.description {
  font-size: 1rem;
  color: rgb(207, 0, 107);
  margin: 10px 0;
}

.homeLink {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  color: #ffffff;
  background-color: rgb(226, 0, 116);
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.homeLink:hover {
  background-color: rgb(248, 0, 128);
}
