.default {
  display: block;
  width: 300px;
}

.mB {
  margin-bottom: -30px;
}

.notFound {
  margin: 0 auto;
}

.gnt {
  display: block;
  width: 400px;
  opacity: 0.1;
  position: fixed;
  z-index: -9000;
}

.lcs {
  display: block;
  width: 52px;
  margin-bottom: -2px;
  z-index: -9000;
}
