.wrapper {
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  text-align: center;
}

.wrapper h2 {
  margin-bottom: 10px;
  font-size: 1.5em;
  color: #e20074;
}

.wrapper p {
  margin-bottom: 12px;
  font-size: 1em;
  color: #e20074;
}

.wrapper button {
  padding: 10px 20px;
  font-size: 0.9em;
  border-radius: 8px;
  border: none;
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23FFFFFF" stroke="%23E20074" stroke-width="1" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>')
      7 0,
    auto;
  margin: 0 5px;
}

.wrapper button:first-of-type {
  background-color: rgb(117, 117, 117);
  color: #fff;
  transition: 0.15s ease-in;
}

.wrapper button:first-of-type:hover {
  background-color: rgb(167, 167, 167);
  transform: scale(1.05);
}

.wrapper button:first-of-type:active {
  background-color: rgb(151, 151, 151);
  transform: scale(1);
}

.wrapper button:last-of-type {
  background-color: #e20074;
  color: #fff;
  transition: 0.15s ease-in;
}

.wrapper button:last-of-type:hover {
  background-color: #ff2194;
  transform: scale(1.05);
}

.wrapper button:last-of-type:active {
  background-color: #ff0084;
  transform: scale(1);
}

@media (max-width: 400px) {
  .wrapper {
    width: 90%;
    padding: 15px;
  }

  .wrapper button {
    width: 100%;
    margin: 5px 0;
  }
}
