.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #e20074;
  padding: 15px;
  width: 700px;
  height: 520px;
  max-width: 80%;
  max-height: 80%;
  border-radius: 8px;
  position: relative;
}

.wrapper h1 {
  color: #e20074;
  margin-bottom: 20px;
}

.wrapper2 {
  color: #e20074;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

#lmao {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

#lmao input {
  color: #e20074;
  accent-color: #e20074;
  vertical-align: middle;
  display: inline-block;
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23FFFFFF" stroke="%23E20074" stroke-width="1" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>')
      7 0,
    auto;
  width: fit-content;
  margin-top: 3px;
  margin-right: 3px;
}

#lmao label {
  display: inline-block;
  color: #e20074;
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23FFFFFF" stroke="%23E20074" stroke-width="1" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>')
      7 0,
    auto;
  margin-left: 15px;
}

.wrapper3,
.wrapper4 {
  display: block;
  width: fit-content;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: end;
  margin: 0 10px;
}

.wrapper2 input {
  display: block;
  width: 120px;
  padding: 12px;
  box-sizing: border-box;
  border: 2px solid #979797;
  border-radius: 4px;
  color: #e20074;
  font-size: 0.9em;
  background: #c2c2c200;
  margin: 3px 0 7px;
}

.wrapper2 input:hover {
  background: #c2c2c21e;
}

.wrapper2 input:focus {
  color: #e20074;
  border: 2px solid #e20074;
  outline: none;
  background: #c2c2c21e;
}

.wrapperSteps {
  width: 100%;
}

.sButtons {
  width: fit-content;
  display: block;
  margin: 0 auto;
}

.stpz {
  width: fit-content;
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23FFFFFF" stroke="%23E20074" stroke-width="1" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>')
      7 0,
    auto;
}

.stpzWrap {
  width: 100%;
  height: 80%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-bottom: 22px;
  overflow: auto;
}

.stpzWrap::-webkit-scrollbar {
  width: 12px;
}

.stpzWrap::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 122px;
}

.stpzWrap::-webkit-scrollbar-thumb {
  background-color: #e20074;
  border-radius: 122px;
  border: 3px solid #f0f0f0;
}

#inputz {
  max-width: 95%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: none;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e20074;
  padding-bottom: 10px;
  margin-top: 10px;
}

#inputz div {
  margin: 0px;
  width: fit-content;
  height: fit-content;
  border: none;
  text-align: left;
}

#inputz input {
  display: block;
  width: 140px;
  padding: 12px;
  box-sizing: border-box;
  border: 2px solid #979797;
  border-radius: 4px;
  color: #e20074;
  font-size: 0.9em;
  margin: 3px 0 7px;
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23FFFFFF" stroke="%23E20074" stroke-width="1" stroke-linejoin="round" d="M18 14.88 8.16 3.15c-.26-.31-.76-.12-.76.28v15.31c0 .36.42.56.7.33l3.1-2.6 1.55 4.25c.08.22.33.34.55.26l1.61-.59a.43.43 0 0 0 .26-.55l-1.55-4.25h4.05c.36 0 .56-.42.33-.7Z"></path></svg>')
      6 0,
    auto;
  background: #c2c2c200;
}

#inputz label {
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23FFFFFF" stroke="%23E20074" stroke-width="1" stroke-linejoin="round" d="M18 14.88 8.16 3.15c-.26-.31-.76-.12-.76.28v15.31c0 .36.42.56.7.33l3.1-2.6 1.55 4.25c.08.22.33.34.55.26l1.61-.59a.43.43 0 0 0 .26-.55l-1.55-4.25h4.05c.36 0 .56-.42.33-.7Z"></path></svg>')
      6 0,
    auto;
  min-width: 90%;
}

#inputz input:hover {
  background: #c2c2c200;
}

#inputz input:focus {
  color: #e20074;
  border: 2px solid #e20074;
  outline: none;
  background: #c2c2c21e;
}

.cfg {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.cfg div {
  width: 500px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 280px;
}

.cfg h4 {
  color: #e20074;
  margin-top: 5px;
  margin-bottom: 3px;
}

.cfg #ye {
  display: block;
  width: 80%;
  margin: 0 auto;
  border-top: 1px solid #e20074;
  padding-top: 7px;
  margin-top: 7px;
}

.cfg div {
  width: fit-content;
  border-right: 1px solid #e20074;
  text-align: center;
}

.cfg div:nth-child(2) {
  margin-top: 13px;
}

.cfg div:last-child {
  width: fit-content;
  border-right: none;
  text-align: center;
}

.cfg label {
  border: 1px solid #ffffff00;
  display: inline-block;
  min-width: fit-content;
  width: 140px;
  color: #e20074;
  max-width: 80%;
  margin: 5px;
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23FFFFFF" stroke="%23E20074" stroke-width="1" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>')
      7 0,
    auto;
}

.cfg input {
  display: inline;
  margin-right: 5px;
}

#asddz {
  text-align: left;
  height: fit-content;
  width: 50%;
  display: flex;
  flex-direction: row;
}

#asddz {
  padding-top: 10px;
}
.ndr,
.snr,
.crd {
  display: flex;
  flex-direction: row;
  width: 55%;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (max-width: 397px) {
  .cfg div {
    border-right: none;
  }
  .cfg div:nth-child(2) {
    border-bottom: 1px solid #e20074;
    padding-bottom: 10px;
  }
}

/* @media screen and (max-width: 440px) {
  .ndr {
    height: 1000px;
    display: flex;
    flex-direction: row;
  }

  .ndr div {
    margin-right: 0px !important;
    text-align: left !important;
  }
} */

/* .ndr div:nth-child(-n + 9) {
  text-align: right;
  margin-right: 40px;
} */
.ndr div,
.snr div,
.crd div {
  border: 1px solid rgba(0, 0, 0, 0);
  width: 140px;
}

.ndr input,
.snr input,
.cfg input,
.crd input {
  color: #e20074;
  accent-color: #e20074;
  vertical-align: middle;
  display: inline-block;
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23FFFFFF" stroke="%23E20074" stroke-width="1" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>')
      7 0,
    auto;
}

.ndr label,
.snr label,
.crd label {
  display: inline-block;
  color: #e20074;
  margin: 5px;
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23FFFFFF" stroke="%23E20074" stroke-width="1" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>')
      7 0,
    auto;
}

.ezs {
  display: block;
  margin: -10px 0 10px;
  color: #e20074;
}
