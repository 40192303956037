.screenUnavaible .wrapper3 {
  pointer-events: none;
  opacity: 0.6;
}

.screenUnavaible .table {
  pointer-events: none;
  opacity: 0.6;
}

.table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
  text-align: center;
}

.table th,
.table td {
  background-color: #f4f4f4;
  color: #333;
  padding: 5px;
  transition: background-color 0.1s, box-shadow 0.1s;
}

.table th {
  background-color: #e20074;
  color: #fff;
  font-weight: bold;
  padding: 15px 5px;
  box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.1);
  font-size: 1.05em;
}

.table td {
  background-color: #fff;
}

.table tr:nth-child(even) td {
  background-color: #f9f9f9;
}

.table tr:hover td {
  background-color: #f1f1f1;
}

.table th:last-child,
.table td:last-child {
  background-color: #f9f9f9;
  color: #333;
  white-space: nowrap;
  border-left: 2px solid #e20074;
}

.table th:last-child {
  border-left: 2px solid #f9f9f9;
  background-color: #e20074;
  color: #fff;
}

.table tr:hover td:last-child {
  background-color: #f1f1f1;
}

.table button {
  background-color: #e20074;
  color: #fff;
  border: 1px solid #e20074;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23FFFFFF" stroke="%23E20074" stroke-width="1" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>')
      7 0,
    auto;
  transition: background-color 0.3s, transform 0.2s;
  margin: 2px;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.table button:hover {
  background-color: #c4005e;
  font-size: 14px;
  transform: scale(1.05);
}

.table button:active {
  background-color: #a3004a;
  transform: scale(1);
}

#kek td {
  background-color: rgba(255, 255, 0, 0.555);
}

#kek td:last-child {
  background-color: #f9f9f9;
}

.table tbody tr:last-child {
  border-bottom: 2px solid #e20074;
}

.h1 {
  color: #e20074;
  margin-left: 5px;
}

.pag {
  min-width: 172px;
  text-align: center;
  color: #e20074;
}

@media screen and (max-width: 586px) {
  .pag {
    margin-right: 450px;
  }
}

.pag button {
  text-align: center;
  border: none;
  outline: none;
}

.pag .button1 {
  background: #e20074;
  border: none;
  outline: none;
  color: #fff;
  margin: 3px;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 1em;
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23FFFFFF" stroke="%23E20074" stroke-width="1" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>')
      7 0,
    auto;
  transition: 0.3s ease;
}

/* cursor: not-allowed; */
.pag .buttonDis {
  background: #e20074;
  border: none;
  outline: none;
  color: #fff;
  margin: 3px;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 1em;
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23FFFFFF" stroke="%23E20074" stroke-width="0.75" d="m16.24 12 3.18-3.18a1.5 1.5 0 0 0 0-2.12L17.3 4.58a1.5 1.5 0 0 0-2.12 0L12 7.76 8.82 4.58a1.5 1.5 0 0 0-2.12 0L4.58 6.7a1.5 1.5 0 0 0 0 2.12L7.76 12l-3.18 3.18a1.5 1.5 0 0 0 0 2.12l2.12 2.12a1.5 1.5 0 0 0 2.12 0L12 16.24l3.18 3.18a1.5 1.5 0 0 0 2.12 0l2.12-2.12a1.5 1.5 0 0 0 0-2.12L16.24 12Z"></path></svg>')
      11 11,
    auto;
  transition: 0.3s ease;
  opacity: 0.8;
}

.pag .button1:hover {
  background-color: rgb(235, 0, 121);
  transform: scale(1.05);
}

.pag .button1:active {
  opacity: 1;
  transform: scale(1);
}

.pag select {
  background-color: #e20074;
  outline: none;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 3px;
  margin: 3px 2px;
}

.screen {
  width: fit-content;
  min-width: 100%;
}

.wrapper3 {
  display: flex;
  align-items: center;
  position: relative;
  margin: 5px;
}

.kekwx {
  display: flex;
  width: 55%;
  justify-content: space-between;
  align-items: center;
}

.wrapper4 {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 45%;
}

.group {
  display: flex;
  line-height: 28px;
  align-items: center;
  position: relative;
  min-width: 150px;
  margin-left: 100px;
}

.input {
  width: 100%;
  height: 40px;
  line-height: 28px;
  padding: 0 1rem;
  padding-left: 2.5rem;
  border: 2px solid transparent;
  border-radius: 8px;
  outline: none;
  background-color: #f3f3f4;
  color: #0d0c22;
  transition: 0.3s ease;
  color: #e20074;
}

.input::placeholder {
  color: #9e9ea7;
}

.input:focus,
.input:hover {
  outline: none;
  border-color: rgba(234, 76, 137, 0.4);
  background-color: #fff;
  box-shadow: 0 0 0 4px rgb(234 76 137 / 10%);
}

.icon {
  position: absolute;
  left: 1rem;
  fill: #e20074;
  width: 1rem;
  height: 1rem;
}

.input[type="search"]::-webkit-search-clear-button {
  background-color: #e20074;
  color: red;
}

.input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  background-color: #e20074;
  -webkit-mask-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
  background-size: 15px 15px;
  height: 15px;
  width: 15px;
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23FFFFFF" stroke="%23E20074" stroke-width="1" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>')
      7 0,
    auto;
}

#clear {
  border-bottom: 2px solid #fff;
}

#kek2 {
  padding: 10px 10px 10px 65px;
  font-size: 2em;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #e20074;
}

/* cursor: not-allowed; */
#nope {
  opacity: 0.6;
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23FFFFFF" stroke="%23E20074" stroke-width="0.75" d="m16.24 12 3.18-3.18a1.5 1.5 0 0 0 0-2.12L17.3 4.58a1.5 1.5 0 0 0-2.12 0L12 7.76 8.82 4.58a1.5 1.5 0 0 0-2.12 0L4.58 6.7a1.5 1.5 0 0 0 0 2.12L7.76 12l-3.18 3.18a1.5 1.5 0 0 0 0 2.12l2.12 2.12a1.5 1.5 0 0 0 2.12 0L12 16.24l3.18 3.18a1.5 1.5 0 0 0 2.12 0l2.12-2.12a1.5 1.5 0 0 0 0-2.12L16.24 12Z"></path></svg>')
      11 11,
    auto;
}

#nope:hover {
  transform: scale(1);
}
