.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wrapper > :last-child {
  margin: 0 0 100px;
}

.anotherWrapper {
  width: fit-content;
  padding: 15px;
  border-radius: 2%;
}

.pass {
  margin-top: 5px;
}

.inputGroup {
  position: relative;
}

.inputGroup:nth-child(2) {
  margin-top: 10px;
}

.input {
  border: solid 1.5px #9e9e9e;
  border-radius: 1rem;
  background: none;
  padding: 1rem;
  font-size: 1rem;
  color: rgb(226, 0, 116);
  transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.userLabel {
  position: absolute;
  left: 15px;
  color: #dacece;
  pointer-events: none;
  transform: translateY(1rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.input:focus,
.input:valid {
  outline: none;
  border: 1.5px solid #4b5966;
}

.input:focus ~ .userLabel,
.input:valid ~ .userLabel {
  transform: translateY(-50%) scale(0.8);
  background-color: #ffffff;
  padding: 0 0.2em;
  color: #4b5966;
}

.error {
  text-align: center;
  color: rgb(226, 0, 116);
  margin: 8px 0 -3px;
}

/* cursor: pointer; */
.kopce {
  display: block;
  margin: 10px auto 0;
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 1em;
  background-color: rgb(226, 0, 116);
  border-radius: 10%;
  color: #fff;
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23FFFFFF" stroke="%23E20074" stroke-width="1" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>')
      7 0,
    auto;
  border-radius: 5px;
  outline: none;
  border: 1px solid rgb(110, 110, 110);
  transition: background-color 0.1s ease;
}

.unavailableButton {
  display: block;
  margin: 10px auto 0;
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 1em;
  background-color: rgb(226, 0, 116);
  border-radius: 10%;
  color: #fff;
  cursor: wait;
  opacity: 0.5;
  border-radius: 5px;
  outline: none;
  border: 1px solid rgb(110, 110, 110);
}

.kopce:hover {
  background-color: rgb(241, 8, 129);
}

.kopce:active {
  background-color: rgb(201, 0, 104);
}
