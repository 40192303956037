* {
  margin: 0;
  padding: 0;
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23FFFFFF" stroke="%23E20074" stroke-width="1" stroke-linejoin="round" d="M18 14.88 8.16 3.15c-.26-.31-.76-.12-.76.28v15.31c0 .36.42.56.7.33l3.1-2.6 1.55 4.25c.08.22.33.34.55.26l1.61-.59a.43.43 0 0 0 .26-.55l-1.55-4.25h4.05c.36 0 .56-.42.33-.7Z"></path></svg>')
      6 0,
    auto;
}

#mySteps > div {
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23FFFFFF" stroke="%23E20074" stroke-width="1" stroke-linejoin="round" d="M18 14.88 8.16 3.15c-.26-.31-.76-.12-.76.28v15.31c0 .36.42.56.7.33l3.1-2.6 1.55 4.25c.08.22.33.34.55.26l1.61-.59a.43.43 0 0 0 .26-.55l-1.55-4.25h4.05c.36 0 .56-.42.33-.7Z"></path></svg>')
      6 0,
    auto;
}

#root > div > div > div > div > div > button.rs-btn {
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23FFFFFF" stroke="%23E20074" stroke-width="1" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>')
      7 0,
    auto;
}

#root > div > div > div > div > div > button.rs-btn-disabled {
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23FFFFFF" stroke="%23E20074" stroke-width="0.75" d="m16.24 12 3.18-3.18a1.5 1.5 0 0 0 0-2.12L17.3 4.58a1.5 1.5 0 0 0-2.12 0L12 7.76 8.82 4.58a1.5 1.5 0 0 0-2.12 0L4.58 6.7a1.5 1.5 0 0 0 0 2.12L7.76 12l-3.18 3.18a1.5 1.5 0 0 0 0 2.12l2.12 2.12a1.5 1.5 0 0 0 2.12 0L12 16.24l3.18 3.18a1.5 1.5 0 0 0 2.12 0l2.12-2.12a1.5 1.5 0 0 0 0-2.12L16.24 12Z"></path></svg>')
      11 11,
    auto;
}

body {
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

body::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 122px;
}

body::-webkit-scrollbar-thumb {
  background-color: #e20074;
  border-radius: 122px;
  border: 3px solid #f0f0f0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* GenerateIoTScreen */
#mySteps div.rs-steps-item-content div {
  color: #e20074;
}

#mySteps
  > div.rs-steps-item-active.rs-steps-item.rs-steps-item-status-process
  > div.rs-steps-item-icon-wrapper {
  background-color: #e20074;
  border-color: grey;
}

#mySteps div.rs-steps-item-icon-wrapper,
#mySteps div.rs-steps-item-icon-wrapper span,
#mySteps div.rs-steps-item-icon-wrapper span svg,
#mySteps div.rs-steps-item-icon-wrapper span svg path,
#mySteps .rs-steps-item-title {
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23FFFFFF" stroke="%23E20074" stroke-width="1" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>')
      7 0,
    auto;
}

#mySteps
  > div.rs-steps-item.rs-steps-item-status-finish
  > div.rs-steps-item-icon-wrapper {
  background-color: #e20074;
  border-color: rgb(167, 167, 167);
}

#mySteps
  > div.rs-steps-item.rs-steps-item-status-finish
  > div.rs-steps-item-icon-wrapper
  > span
  > svg {
  color: #fff;
}

.rs-steps-horizontal .rs-steps-item-title::after {
  border: rgb(167, 167, 167);
}

#mySteps
  > div.rs-steps-item.rs-steps-item-status-finish
  > div.rs-steps-item-content
  > div::after {
  border-color: #e20074;
}

.rs-btn-group:not(.rs-btn-group-vertical) > .rs-btn:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  color: #fff;
  background-color: #e20074;
}

.rs-btn-group:not(.rs-btn-group-vertical) > .rs-btn:not(:first-child):hover {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  color: #fff;
  background-color: #eb1d87;
}

.rs-btn-group:not(.rs-btn-group-vertical) > .rs-btn:not(:first-child):active {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  color: #fff;
  background-color: rgb(211, 0, 109);
}

.kek
  .rs-btn-group:not(.rs-btn-group-vertical)
  > .rs-btn:not(:first-child):active {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  color: #fff;
  background-color: rgb(211, 0, 109);
}

#lo124128 {
  opacity: 0.6;
}
