.editLicenseScreen {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.editLicenseScreen h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #e20074;
}

.editLicenseScreen form {
  display: flex;
  flex-direction: column;
}

.editLicenseScreen div {
  margin-bottom: 15px;
}

.editLicenseScreen label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.editLicenseScreen input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  color: #e20074;
}

.editLicenseScreen input[readOnly] {
  background-color: #e9e9e9;
}

.editLicenseScreen button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23FFFFFF" stroke="%23E20074" stroke-width="1" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>')
      7 0,
    auto;
  font-size: 16px;
  margin: 5px 0;
}

.editLicenseScreen button[type="submit"] {
  background-color: #e20074;
  color: #fff;
}

.editLicenseScreen button[type="button"] {
  background-color: rgb(143, 143, 143);
  color: #fff;
}

.editLicenseScreen button:hover {
  opacity: 0.9;
}

.lok {
  width: fit-content;
  margin: 10px auto 0;
  color: #e20074;
  text-align: center;
}

.specialButton{
  padding: 7px 3px 7px 7px;
  color: #e20074;
}